import React from 'react';
import { AiOutlineMinus, AiOutlinePlus, AiFillDelete } from 'react-icons/ai';
import style from './Cart.module.css';
import { useDispatch } from 'react-redux';
import { closeCart, decreaseQTY, increaseQTY, removeItem } from '../../Redux/Cart.js';
import { Link } from 'react-router-dom';
const CartItems = ({ items }) => {
	const totalPrice = items.reduce((acc, product) => {
		acc += product.price * product.quantity;
		return acc;
	}, 0);
	const dispatch = useDispatch();
	console.log(items);
	return (
		<div className='p-3 my-5'>
			{/*items container*/}
			{items.map((item, index) => {
				return (
					<div className='d-flex justify-content-between align-items-center mb-5' key={index}>
						{/*img container*/}
						<div className={style.imgContainer}>
							<img className={style.img} src={item.img} alt={item.title} />{' '}
						</div>
						{/*title | quantity * price | decrease  | quantity | increase*/}
						<div>
							<h4 className='text-center mb-5'>{item.title}</h4>
							<p className='mb-4 text-center'>
								{item.quantity} *<span className='fw-bold'> {item.price}</span> USD
							</p>
							{/*decrease | quantity | increase*/}
							<div className='d-flex justify-content-center align-items-center gap-4'>
								<div className={`d-flex justify-content-center align-items-center ${style.box}`}>
									<AiOutlineMinus className={style.icon} onClick={() => dispatch(decreaseQTY(item))} />
								</div>
								<div className={`d-flex justify-content-center align-items-center ${style.box}`}>
									<span>{item.quantity}</span>
								</div>
								<div className={`d-flex justify-content-center align-items-center ${style.box}`}>
									<AiOutlinePlus className={style.icon} onClick={() => dispatch(increaseQTY(item))} />
								</div>
							</div>
						</div>
						<div>
							<p className={`${style.totalPrice} fw-bold`}> Total : {item.price * item.quantity} USD</p>
							<div className={`d-flex justify-content-center align-items-center ${style.box}`}>
								<AiFillDelete className={`${style.icon} text-center ms-auto`} onClick={() => dispatch(removeItem(item))} />
							</div>
						</div>
					</div>
				);
			})}
			{/*check out*/}
			<div className='my-5'>
				{/*subtotal*/}
				<div className='d-flex justify-content-between align-items-center'>
					<p className='fw-bold'>Sub total : </p>
					<p className='fw-bold'>{totalPrice} USD</p>
				</div>
				<button onClick={() => dispatch(closeCart())} className={style.checkOut}>
					<Link className={style.checkoutLink} to='/cart'>
						View Cart
					</Link>
				</button>
			</div>
		</div>
	);
};

export default CartItems;
