import React from 'react';
import { RxDoubleArrowLeft } from 'react-icons/rx';

import { useDispatch , useSelector } from 'react-redux';
import { closeCart , clearCart } from '../../Redux/Cart';
import style from './Cart.module.css'

const CartCounter = () => {
  const state = useSelector((state) => state.cart)
	const dispatch = useDispatch();
	return (
		<div className='d-flex justify-content-between align-items-center p-3'>
			<RxDoubleArrowLeft size={40} onClick={() => dispatch(closeCart(false))} />
        {state.cartItems.length > 0 ? 	<p className= {style.clear} onClick = {() => dispatch(clearCart())}>Clear Cart</p> : ''}
		</div>
	);
};

export default CartCounter;
