import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Toaster } from 'react-hot-toast';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import store from './Redux/Store';
import { Provider } from 'react-redux';
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Toaster position='top-center' reverseOrder={false} />
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
