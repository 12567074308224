import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import style from './Cart.module.css';
import CartCounter from './CartCounter';
import CartItems from './CartItems';
import EmptyCart from './EmptyCart';
import { closeCart } from '../../Redux/Cart';
const Cart = () => {
	const state = useSelector((state) => state.cart);
	const cartSideBar = useRef(null);
  const dispatch = useDispatch();
  
	useEffect(() => {
		const handleClickOutSide = (e) => {
			if (cartSideBar.current && !cartSideBar.current.contains(e.target)) {
				dispatch(closeCart());
			}
		};
		document.addEventListener('mousedown', handleClickOutSide);
		return () => {
			document.removeEventListener('mousedown', handleClickOutSide);
		};
	}, []);
	return (
		<div ref={cartSideBar} className={style.cartContainer}>
			<div className={`${state.cartState ? style.show : style.hide} ${style.cart}`}>
				<CartCounter />
				{state.cartItems.length > 0 ? <CartItems items={state.cartItems} /> : <EmptyCart />}
			</div>
		</div>
	);
};

export default Cart;
