
import '../list/list.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { products } from '../../Fakers/fakers';
import style from './products.module.css';


import {  useSelector } from 'react-redux';


import Cart from '../../components/Cart/Cart';
import ShowProduct from './ShowProduct';
const List = () => {
	
	const state = useSelector((state) => state.cart);


	return (
		<div className='list'>
			<Sidebar />
			<div className='listContainer'>
				<Navbar />
				<div className='container my-5'>
					<div className='row '>
            {products.map((item, index) => (
              <ShowProduct key={index} product={item} />
            )
            )}
								
							
					
					</div>
				</div>
			</div>
			{state.cartState ? <div className={style.overlay}></div> : ''}

			<Cart />
		</div>
	);
};

export default List;
