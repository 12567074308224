import './login.scss';
import loginImg from '../../assets/nfc.jpg';
import { FcGoogle } from 'react-icons/fc';
import { BsFacebook } from 'react-icons/bs';
const Login = () => {
	return (
		<div className='container-fluid'>
			<div className='row h-100'>
				{/*img container*/}
				<div className='col-12 col-md-6 mb-4 mb-md-0 imgContainer'>
					<img src={loginImg} alt='login/img' className=' w-100 h-100' />
				</div>
				<div className='col-12 col-md-6 position-relative text-center'>
					<div className='loginCardContainer my-5 '>
						<form className='my-5'>
							<input className='w-100 text-center p-2 my-4 input' type='text' placeholder='enter your email' />
							<input className='w-100 text-center p-2 my-4 input' type='password' placeholder='enter you password' />
							{/*login with gmail or facebook*/}
							<div className='my-4'>
								<p className='fw-bold fs-4 mb-3'>Or Login With</p>
								<BsFacebook className='me-4' size={40} />
								<FcGoogle size={40} />
							</div>
							<button className='btn'>Sign in</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
