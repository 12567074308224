import './datatable.scss';
import { DataGrid } from '@mui/x-data-grid';
import { userColumns, userRows } from '../../datatablesource';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Datatable = () => {
	const [data, setData] = useState([]);
	useEffect(() => {
		// const storedCards = localStorage.getItem('card');
		if (userRows) {
			setData(userRows);
		}
	}, []);
	const handleDelete = (id) => {
		const updatedCard = data.filter((card) => card.id !== id);
		localStorage.setItem('card', JSON.stringify(updatedCard));
		setData(updatedCard);
		// setData(data.filter((item) => item.id !== id));
		// localStorage.setItem('card', JSON.stringify(data));
	};

	const actionColumn = [
		{
			field: 'action',
			headerName: 'Action',
			width: 200,
			renderCell: (params) => {
				// console.log(params);
				return (
					<div className='cellAction'>
						<Link to='/users/test' style={{ textDecoration: 'none' }}>
							<div className='viewButton'>View</div>
						</Link>
						<div className='deleteButton' onClick={() => handleDelete(params.row.id)}>
							Delete
						</div>
					</div>
				);
			},
		},
	];
	return (
		<>
			{userRows.length > 0 ? (
				<div className='datatable'>
					<div className='datatableTitle'>
						Add New Card
						<Link to='/users/new' className='link'>
							Add New
						</Link>
					</div>
					<DataGrid className='datagrid' rows={data} columns={userColumns.concat(actionColumn)} pageSize={9} rowsPerPageOptions={[9]} checkboxSelection />
				</div>
			) : (
				<div className='datatable'>
					<div className='datatableTitle'>
						Add New Card
						<Link to='/users/new' className='link'>
							Add New
						</Link>
					</div>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Cards Found</div>
				</div>
			)}
		</>
	);
};

export default Datatable;
