import productOne from '../assets/product-01.png';
import productTwo from '../assets/product-02.png';
import productThree from '../assets/product-03.png';
import productFour from '../assets/product-04.png';
import productFive from '../assets/product-05.png';
import productSix from '../assets/product-06.png';
import productSeven from '../assets/product-07.png';
import productEight from '../assets/product-08.png';
import productNine from '../assets/product-09.png';
export const products = [
	{
		id: 1,
		img: productOne,
		title: 'Air Jordan',
		price: 800,
		quantity: 1,
	},
	{
		id: 2,
		img: productTwo,
		title: 'Air Jordan',
		price: 600,
		quantity: 1,
	},
	{
		id: 3,
		img: productThree,
		title: 'Air Jordan',
		price: 800,
		quantity: 1,
	},
	{
		id: 4,
		img: productFour,
		title: 'Air Jordan',
		price: 1200,
		quantity: 1,
	},
	{
		id: 5,
		img: productFive,
		title: 'Air Jordan',
		price: 500,
		quantity: 1,
	},
	{
		id: 6,
		img: productSix,
		title: 'Air Jordan',
		price: 1000,
		quantity: 1,
	},
	{
		id: 7,
		img: productSeven,
		title: 'Sporting',
		price: 800,
		quantity: 1,
	},
	{
		id: 8,
		img: productEight,
		title: 'Air Jordan',
		price: 600,
		quantity: 1,
	},
	{
		id: 9,
		img: productNine,
		title: 'Flat Shoes',
		price: 700,
		quantity: 1,
	},
];
