import React, { useState } from 'react';
import style from './ShowProduct.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, increaseQTY, decreaseQTY } from '../../Redux/Cart';
import { BsCartCheck } from 'react-icons/bs';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

const ShowProduct = ({ product }) => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.cart);
	const isProductInCart = state.cartItems.some((item) => item.id === product.id);
	const [qty, setQty] = useState(1);
	const handleAddToCart = (item) => {
		dispatch(
			addToCart({
				id: item.id,
				title: item.title,
				img: item.img,
				quantity: qty,
				price: item.price,
			})
		);
	};

	return (
		<div className={`col-12 col-md-6 col-lg-4 ${style.productCard}`}>
			{/*img container*/}
			<div className={style.imgContainer}>
				<img src={product.img} alt={product.title} className={style.productImg} />
			</div>
			{/*title container*/}
			<div className='text-center my-5'>
				<h4>{product.title}</h4>
			</div>
			{/*price container*/}
			<div className='text-center'>
				<p> Price : {product.price} USD</p>
			</div>
			<div className='d-flex justify-content-center align-items-center gap-3'>
				<AiOutlineMinus onClick={() => setQty(qty - 1)} />

				<p className='my-2'>{qty}</p>
				<AiOutlinePlus onClick={() => setQty(qty + 1)} />
			</div>
			{/*add to cart btn*/}

			<div className='text-center mx-auto my-3'>
				{isProductInCart ? <p>Product already added to cart before</p> : <BsCartCheck size={30} className={style.cartIcon} onClick={() => handleAddToCart(product)} />}
			</div>
		</div>
	);
};

export default ShowProduct;
