import './sidebar.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import StoreIcon from '@mui/icons-material/Store';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from 'react-router-dom';
import { DarkModeContext } from '../../context/darkModeContext';
import { useContext } from 'react';

const Sidebar = () => {
	const { dispatch } = useContext(DarkModeContext);
	return (
		<div className='sidebar'>
			<div className='top'>
				<Link to='/' style={{ textDecoration: 'none' }}>
					<span className='logo'>Q-Tab</span>
				</Link>
			</div>
			<hr />
			<div className='center'>
				<ul>
					<p className='title'>MAIN</p>
					<Link to='/' style={{ textDecoration: 'none' }}>
						<li>
							<DashboardIcon className='icon' />
							<span>Dashboard</span>
						</li>
					</Link>

					<p className='title'>LISTS</p>

					<Link to='/cards' style={{ textDecoration: 'none' }}>
						<li>
							<StoreIcon className='icon' />
							<span>Cards</span>
						</li>
					</Link>
					<Link to='/products' style={{ textDecoration: 'none' }}>
						<li>
							<StoreIcon className='icon' />
							<span>Products</span>
						</li>
					</Link>
					<p className='title'>USEFUL</p>

				

					<p className='title'>USER</p>
					<li>
						<AccountCircleOutlinedIcon className='icon' />
						<span>Profile</span>
					</li>
					<li>
						<SettingsApplicationsIcon className='icon' />
						<span>Account Settings</span>
					</li>
					<Link to='/login' style={{ textDecoration: 'none' }}>
						<li>
							<ExitToAppIcon className='icon' />
							<span>Login</span>
						</li>
					</Link>
				</ul>
			</div>
		</div>
	);
};

export default Sidebar;
