import Home from './pages/home/Home';
import Login from './pages/login/Login';
import List from './pages/list/List';
import Single from './pages/single/Single';
import New from './pages/new/New';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { productInputs, userInputs } from './formSource';
import './style/dark.scss';
import { useContext } from 'react';
import { DarkModeContext } from './context/darkModeContext';
import Products from './pages/products/Products';
import CartPage from './pages/Cart/Cart';
import Checkout from './pages/Checkout/Checkout';
function App() {
	const { darkMode } = useContext(DarkModeContext);

	return (
		<div className={darkMode ? 'app dark' : 'app'}>
			<BrowserRouter>
				<Routes>
					<Route path='/'>
						<Route index element={<Home />} />
						<Route path='login' element={<Login />} />
						<Route path='users'>
							<Route index element={<List />} />
							<Route path=':userId' element={<Single />} />
							<Route path='new' element={<New inputs={userInputs} title='Add New Card' />} />
						</Route>
						<Route path='cards'>
							<Route index element={<List />} />
							<Route path=':cardId' element={<Single />} />
							<Route path='new' element={<New inputs={productInputs} title='Add New Product' />} />
						</Route>
						<Route index path='products' element={<Products />} />
						<Route index path='cart' element={<CartPage />} />
						<Route index path='checkout' element={<Checkout />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
