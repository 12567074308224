import React from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { increaseQTY, decreaseQTY } from '../../Redux/Cart';
import { Link } from 'react-router-dom';
import Cart from '../../components/Cart/Cart';
import style from './Cart.module.css';
const CartPage = () => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.cart.cartItems);
	const cartState = useSelector((state) => state.cart);
	const totalPrice = state.reduce((acc, product) => {
		acc += +product.price * +product.quantity;
		return acc;
	}, 0);
	console.log(state);
	return (
		<div className='container-fluid row'>
			<div className='col-2'>
				<Sidebar />
			</div>
			<div className='col-10'>
				<Navbar />
				<div className='my-5'>
					<table class='table'>
						<thead>
							<tr>
								<th scope='col'>Products</th>
								<th scope='col'>Price</th>
								<th scope='col'>QTY</th>
								<th scope='col'>Total</th>
							</tr>
						</thead>
						<tbody>
							{state.map((item, index) => {
								return (
									<tr>
										<td>{item.title}</td>
										<td>{item.price}</td>
										<td className='d-flex gap-3'>
											<AiOutlineMinus onClick={() => dispatch(decreaseQTY(item))} />
											<p>{item.quantity}</p>
											<AiOutlinePlus onClick={() => dispatch(increaseQTY(item))} />
										</td>
										<td>{item.price * item.quantity}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className='text-center my-4'>
						<p className='fw-bold'>Total Price : {totalPrice} USD</p>
					</div>
					<button className={style.btn}>
						<Link className={style.linkBtn} to='/checkout'>
							Next
						</Link>
					</button>
				</div>
			</div>
                

			<Cart />
		</div>
	);
};

export default CartPage;
