import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import style from './Checkout.module.css';
import { useSelector } from 'react-redux';

const Checkout = () => {
	const state = useSelector((state) => state.cart.cartItems);
	const totalPrice = state.reduce((acc, product) => {
		acc += +product.quantity * product.price;
		return acc;
	}, 0);
	const shippingCoast = 50;
	const discount = 20;
	return (
		<div className='container-fluid row'>
			<div className='col-2'>
				<Sidebar />
			</div>
			<div className='col-10'>
				<Navbar />
				<div className='my-5'>
					<form action=''>
						<label htmlFor='city' className='d-block fw-bold'>
							City
						</label>
						<select name='' id='city' className={`d-block p-2 w-100 ${style.inp} mb-3`}>
							<option>Mansoura</option>
							<option value=''>alexandria</option>
							<option value=''>Cairo</option>
							<option value=''>Luxor</option>
						</select>
						<label htmlFor='address' className='fw-bold d-block'>
							Address
						</label>
						<input type='text' className={`${style.inp} d-block p-2 w-100 mb-3`} id='address' />
						<label htmlFor='phone' className='fw-bold d-block'>
							Phone
						</label>
						<input type='number' className={`${style.inp} d-block p-2 w-100 mb-3`} id='phone' />
						<label htmlFor='postal' className='fw-bold d-block'>
							Postal Code
						</label>
						<input type='number' className={`${style.inp} d-block p-2 w-100 mb-3`} id='postal' />
					</form>

					<p className='my-5 text-center fw-bold fs-3'>Payment</p>
					<form action=''>
						<div>
							<input type='radio' id='visa' name='payment' />
							<label htmlFor='visa' className='mr-2 fw-bold'>
								Visa / masterCard
							</label>
						</div>
						<div>
							<input type='radio' id='cash' name='payment' />
							<label htmlFor='visa' className='mr-2 fw-bold'>
								Cash
							</label>
						</div>
					</form>
					<div className={style.brief}>
						{/*order*/}
						<div className={style.orderContainer}>
							<h5 className='my-2 p-3'>Your order</h5>
							<div className='p-3'>
								{state.map((item, index) => {
									return (
										<div key={index} className='d-flex justify-content-between align-items-center'>
											<p>
												{item.quantity} * {item.title}
											</p>
											<p>{item.price * item.quantity}</p>
										</div>
									);
								})}
							</div>
						</div>
						<div className={style.totalContainer}>
							<div className='p-3'>
								<p className='fw-bold'>Total</p>
								<div className='d-flex justify-content-between align-items-center'>
									<p className='fw-bold'>Subtotal : </p>
									<p>{totalPrice}</p>
								</div>
							</div>
						</div>
						<div className={style.shippingContainer}>
							<div className='p-3'>
								<p className='mb-4 fw-bold'>Shipping</p>
								<div className='d-flex justify-content-between align-items-center'>
									<p className='fw-bold'>shipping coast</p>
									<p>{shippingCoast}</p>
								</div>
							</div>
						</div>
						<div className={style.final}>
							<div className='p-3'>
								<div className='d-flex justify-content-between align-items-center mb-2'>
									<p className='fw-bold'>Total</p>
									<p>{totalPrice}</p>
								</div>
								<div className='d-flex justify-content-between align-items-center mb-2'>
									<p className='fw-bold'>Discount</p>
									<p>{discount}</p>
								</div>
								<div className='d-flex justify-content-between align-items-center mb-2'>
									<p className='fw-bold'>Shipping Coast</p>
									<p>{shippingCoast}</p>
								</div>
								<div className='d-flex justify-content-between align-items-center mb-2'>
									<p className='fw-bold'>Net Price</p>
									<p>{totalPrice + shippingCoast - discount}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Checkout;
