import React from 'react';
import clearBag from '../../assets/emptybag.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeCart } from '../../Redux/Cart.js';
import style from './Cart.module.css';
const EmptyCart = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const backToHome = () => {
		dispatch(closeCart());
		navigate('/');
	};
	return (
		<div className='d-flex justify-content-center align-items-center w-100 h-100 my-3'>
			<div>
				<img src={clearBag} alt='' className={style.bag} />
				<button className={style.navigate} onClick={backToHome}>
					Back to home Page
				</button>
			</div>
		</div>
	);
};

export default EmptyCart;
