import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import './home.scss';
import Widget from '../../components/widget/Widget';
import Featured from '../../components/featured/Featured';
import Chart from '../../components/chart/Chart';
import Table from '../../components/table/Table';
import Cart from '../../components/Cart/Cart';
import { useSelector } from 'react-redux';
const Home = () => {
	const state = useSelector((state) => state.cart);
	return (
		<div className='home'>
			<Sidebar />
			<div className='homeContainer'>
				<Navbar />
				<div className='widgets'>
					<Widget type='user' />
					<Widget type='order' />
					<Widget type='earning' />
				</div>
				<div className='charts'>
					<Chart title='Last Month Visit ' aspect={2 / 1} />
				</div>
				<div className='listContainer'>
					<Table />
				</div>
				{state.cartState ? <div className='overlay'></div> : ''}
				<Cart />
			</div>
		</div>
	);
};

export default Home;
