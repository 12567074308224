import './navbar.scss';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { DarkModeContext } from '../../context/darkModeContext';
import { useContext } from 'react';
import { BsCartCheck } from 'react-icons/bs';
import { openCart } from '../../Redux/Cart.js';
import { useDispatch } from 'react-redux';
import Cart from '../Cart/Cart';
import { useSelector } from 'react-redux';
const Navbar = () => {
	const dispatcho = useDispatch();
	const { dispatch } = useContext(DarkModeContext);
	const state = useSelector((state) => state.cart);
	return (
		<div className='navbar'>
			<div className='wrapper'>
				<div className='items'>
					<div className='item'>
						<LanguageOutlinedIcon className='icon' />
						English
					</div>

					<div className='item'>
						<NotificationsNoneOutlinedIcon className='icon' />
						<div className='counter'>1</div>
					</div>
					<div className='item'>
						<ChatBubbleOutlineOutlinedIcon className='icon' />
						<div className='counter'>2</div>
					</div>
					<div className='item' onClick={() => dispatcho(openCart(true))}>
						<BsCartCheck className='icon' />
						<div className='counter'>{state.cartItems.length}</div>
					</div>

					<div className='item'>
						<img src='https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500' alt='' className='avatar' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
