import './new.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { userRows } from '../../datatablesource.js';

const New = ({ inputs, title }) => {
	
	const [file, setFile] = useState('');
	const [name, setName] = useState('');
	const [userName, setUserName] = useState('');
	const [password, setPaswword] = useState('');
	const [email, setEmail] = useState('');
	const [theme, setTheme] = useState('');
	const [cardType, setCardType] = useState('');

	const navigate = useNavigate();
	const handleSubmit = (e) => {
		e.preventDefault();
		const fullData = {
			id: uuidv4(),
			img: file,
			name,
			userName,
			password,
			email,
			theme,
			cardType,
		};
		userRows.push(fullData);
		// update local storage
		localStorage.setItem('card', JSON.stringify(userRows));
		toast.success(`your card created successfully mr ${fullData.name}`);
		navigate('/cards');
		window.location.reload();
	};

	return (
		<div className='new'>
			<Sidebar />
			<div className='newContainer'>
				<Navbar />
				<div className='top'>
					<h1>{title}</h1>
				</div>
				<div className='bottom'>
					<div className='left'>
						<img src={file ? URL.createObjectURL(file) : 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'} alt='' />
					</div>
					<div className='right'>
						<form onSubmit={(e) => handleSubmit(e)}>
							<div className='formInput'>
								{/*image*/}
								<label htmlFor='file'>
									Image: <DriveFolderUploadOutlinedIcon className='icon' />
								</label>
								<input type='file' id='file' onChange={(e) => setFile(e.target.files[0])} style={{ display: 'none' }} />
							</div>

							<div className='formInput'>
								<label>UserName</label>
								<input type='text' placeholder='UserName' onChange={(e) => setUserName(e.target.value)} />
							</div>
							<div className='formInput'>
								<label>Full Name</label>
								<input type='text' placeholder='Full Name' onChange={(e) => setName(e.target.value)} />
							</div>
							<div className='formInput'>
								<label>Email</label>
								<input type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
							</div>
							<div className='formInput'>
								<label>Password</label>
								<input type='password' placeholder='Password' onChange={(e) => setPaswword(e.target.value)} />
							</div>
							<div className='formInput'>
								<label>Card type</label>
								<select style={{ width: '100%', padding: '7px' }} onChange={(e) => setCardType(e.target.value)}>
									<option value=''>Select card type</option>
									<option value='Event'>Event</option>
									<option value='Bussiness'>Bussiness</option>
									<option value='Car'>Car</option>
								</select>
							</div>
							<div className='formInput'>
								<label>Theme</label>
								<select style={{ width: '100%', padding: '7px' }} onChange={(e) => setTheme(e.target.value)}>
									<option value=''>selcet your card theme</option>
									<option value='Theme 1'>Theme 1 </option>
									<option value='Theme 2'>Theme 2 </option>
									<option value='Theme 3'>Theme 3 </option>
								</select>
							</div>
							<button>Send</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default New;
